/* eslint-disable react/jsx-filename-extension */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { generateReportsPath } from '@/paths';
import Box from '../Box/index';

function BoxDeliveryIndex({ delivery }) {
  const router = useRouter();

  const title = 'Pedidos com atraso na entrega';
  const description = ' pedidos enviados com atraso de um total de ';

  const helpDescription = (
    <div style={{ padding: '10px' }}>
      Pedidos entregues com atraso nos últimos 45 dias que:
      <ul style={{ marginLeft: '15px' }}>
        <li>Foram entregues após a data limite;</li>
        <li>
          Não possuem informação de data de entrega, e a data limite já expirou.
        </li>
      </ul>
      <i>Obs: pedidos Centauro Envios não são considerados.</i>
    </div>
  );

  function handleEvent() {
    router.push(generateReportsPath);
  }

  return (
    <Box
      grade={delivery}
      title={title}
      description={description}
      helpDescription={helpDescription}
      expectedResult={6}
      handleEvent={handleEvent}
    />
  );
}

BoxDeliveryIndex.propTypes = {
  delivery: PropTypes.shape({}).isRequired,
};

export default BoxDeliveryIndex;
