/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable promise/always-return */
// TODO: Need refactor
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { useSession } from 'next-auth/react';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Subheader from '@/common/components/Subheader/Subheader';
import Toast from '@/common/components/Toast/Toast';
import useCurrentAccount from '@/common/hooks/useCurrentAccount';
import { getSellerIndex } from '@/services/sellerIndexModule';
import BoxCancellationIndex from '../../../reputation/components/BoxCancellationIndex/BoxCancellationIndex';
import BoxDeliveryIndex from '../../../reputation/components/BoxDeliveryIndex/BoxDeliveryIndex';
import BoxExpeditionIndex from '../../../reputation/components/BoxExpeditionIndex/BoxExpeditionIndex';
import BoxNpsIndex from '../../../reputation/components/BoxNPSIndex/BoxNpsIndex';
import BoxSellerIndex from '../../../reputation/components/BoxSellerIndex/BoxSellerIndex';
import ChartSellerIndex from '../../../reputation/components/ChartSellerIndex/ChartSellerIndex';
import {
  BoxRow,
  HeaderIcon,
  BoxContainer,
  SubtitleHeader,
  Subtitle,
  Grid,
  BoxIcon,
  TextError,
} from './Home.styled';
import { breadcrumbSellerIndex } from './constants';

const Home = () => {
  const { data: session } = useSession();
  const { currentAccount } = useCurrentAccount();
  const [grade, setGrade] = useState(0);
  const [expedition, setExpedition] = useState({});
  const [delivery, setDelivery] = useState({});
  const [cancellation, setCancellation] = useState({});
  const [nps, setNps] = useState({});
  const [score, setScore] = useState(0);
  const [error, setError] = useState(false);
  const title = 'Seller Index';

  const handleCloseToast = () => {
    setError(false);
  };

  useEffect(() => {
    const getSellerIndexInfo = () => {
      const accessToken = session?.accessToken;
      getSellerIndex(currentAccount.codigo, accessToken)
        .then((response) => {
          const { data: sellerIndexData, error: err } = response;
          if (err && err.status === 404) {
            return;
          }
          setGrade(sellerIndexData.grade.total);
          setExpedition(sellerIndexData.expedition);
          setDelivery(sellerIndexData.delivery);
          setCancellation(sellerIndexData.cancellation);
          setNps(sellerIndexData.nps);
          setScore(sellerIndexData.nps.score);
        })
        .catch(() => {
          setError(true);
        });
      // localStorage.setItem('accessToken', accessToken);
    };

    if (currentAccount.id) {
      getSellerIndexInfo();
    }
  }, [currentAccount, session?.accessToken]);

  return (
    <>
      <Subheader
        content={{
          title: title,
          icon: <HeaderIcon />,
        }}
        breadcrumbList={breadcrumbSellerIndex}
      />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
      >
        <BoxContainer>
          <BoxRow>
            <Grid item xs={12} lg={6} border>
              <BoxSellerIndex
                grade={grade}
                hasIndex={grade > 0}
                style={{ display: 'flex' }}
              />
            </Grid>
            <Grid item xs={12} lg={6} border>
              <ChartSellerIndex />
            </Grid>
          </BoxRow>
          <Grid item xs={12}>
            <SubtitleHeader>
              {/* <Delivery fill="#4D4D4D" height="28px" width="28px" /> */}
              <Subtitle>Delivery</Subtitle>
            </SubtitleHeader>
            <BoxRow>
              <Grid item xs={12} lg={6} border>
                <BoxExpeditionIndex expedition={expedition} />
              </Grid>
              <Grid item xs={12} lg={6} border>
                <BoxDeliveryIndex delivery={delivery} />
              </Grid>
            </BoxRow>
          </Grid>
          <Grid item xs={12} border>
            <SubtitleHeader>
              {/* <Boxes fill="#4D4D4D" height="24px" width="24px" /> */}
              <Subtitle>Estoque</Subtitle>
            </SubtitleHeader>
            <BoxCancellationIndex cancellation={cancellation} />
          </Grid>
          <Grid item xs={12} border>
            <SubtitleHeader>
              <BoxIcon />
              <Subtitle>Cliente</Subtitle>
            </SubtitleHeader>
            <BoxNpsIndex nps={nps} score={score} />
          </Grid>
        </BoxContainer>
        {error && (
          <Toast
            title="Ocorreu um erro ao carregar os dados"
            onClose={handleCloseToast}
            autoHideDuration={10_000}
          >
            <TextError>
              <FormattedMessage id="report.error.message" />
            </TextError>
          </Toast>
        )}
      </Grid>
    </>
  );
};

export default Home;
