import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    border-radius: ${theme.borderRadius.medium};
    border-width: ${theme.borderWidth.xthin};
    padding: ${theme.spacing[100]};
  `}

  @media (max-width: 768px) {
    width: 328px;
  }
`;
