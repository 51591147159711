import { getJson, RequestHeaders } from './httpClient/httpClientBff';

const webapiUrl = process.env.NEXT_PUBLIC_PORTAL_SELLER_WEBAPI;
const serllerIndexUrl = `${webapiUrl}v1/seller-index`;

const getSellerIndex = (sellerId: string, idToken: string, header = {}) => {
  const url = `${serllerIndexUrl}/${sellerId}`;
  return getJson(url, idToken, header);
};

const getSellerIndexHistoryLite = (
  sellerId: string,
  idToken: string,
  header: RequestHeaders = {},
) => {
  const url = `${serllerIndexUrl}/${sellerId}/history/lite`;
  return getJson(url, idToken, header);
};

export { getSellerIndex, getSellerIndexHistoryLite };
