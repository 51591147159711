import { Snackbar, Slide } from '@mui/material';
import { useState } from 'react';
import {
  StyledErrorIcon,
  StyledSuccessIcon,
  Wrapper,
  StyledTitle,
  StyledDescription,
} from './Toast.styled';

export type ToastType = {
  title: string;
  children?: JSX.Element;
  description?: string;
  onClose?: () => void;
  autoHideDuration?: number;
  success?: boolean;
};

const Toast = ({
  title,
  children,
  description,
  onClose,
  autoHideDuration = 10_000,
  success = false,
}: ToastType) => {
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    if (typeof onClose === 'function') {
      onClose();
    }
    setOpen(false);
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={open}
      autoHideDuration={autoHideDuration}
      TransitionComponent={Slide}
      onClose={handleClose}
    >
      <Wrapper success={success}>
        {success && <StyledSuccessIcon />}
        {!success && <StyledErrorIcon />}
        <div>
          <StyledTitle>{title}</StyledTitle>
          {description ? (
            <StyledDescription>{description}</StyledDescription>
          ) : (
            children
          )}
        </div>
      </Wrapper>
    </Snackbar>
  );
};

export default Toast;
