/* eslint-disable react/jsx-filename-extension */
import { Typography } from '@grupo-sbf/motriz-core';
import { Grid } from '@mui/material';
import moment from 'moment';
import { useTheme } from 'styled-components';
import HelperPopover from '@/common/components/HelperPopover/HelperPopover';
import Faq from '../FAQ/Faq';
import { Root, Header, Title } from './BoxSellerIndex.styled';
import NotaSeller from './NotaSeller';

function BoxSellerIndex({ grade, hasIndex }) {
  const firstDay = moment().subtract(45, 'days').format('DD/MM/YYYY');
  const lastDay = moment().subtract(1, 'days').format('DD/MM/YYYY');
  const {
    color: { neutral },
  } = useTheme();
  return (
    <>
      <Root container>
        <Grid item xs={12}>
          <Header>
            <Title>Seu Seller Index</Title>
            <HelperPopover>
              <Faq />
            </HelperPopover>
          </Header>
          {!hasIndex && (
            <b>Não há dados suficientes para contabilizar a nota</b>
          )}
          {hasIndex && <NotaSeller grade={grade} />}
          <Typography type="simple" size="small" color={neutral[500]} mt="300">
            O resultado esperado é acima de 4
          </Typography>
          <Typography type="simple" size="small" color={neutral[700]} mt="200">
            Período dos últimos 45 dias
          </Typography>
          <Typography type="simple" size="small" color={neutral[700]}>
            {firstDay} a {lastDay}
          </Typography>
        </Grid>
      </Root>
    </>
  );
}

export default BoxSellerIndex;
