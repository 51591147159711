import { Grid } from '@mui/material';
import HelperPopover from '@/common/components/HelperPopover/HelperPopover';
import zeroIfNothing from '@/common/utils';
import {
  Root,
  Header,
  Title,
  HelperPopoverTitle,
  Grade,
  ExpectedResult,
} from './BoxNpsIndex.styled';

function BoxNpsIndex({ nps = { grade: 0, score: 0, totalAnswers: 0 } }) {
  const color = nps?.score >= 75 ? 'green' : 'red';
  return (
    <Root container>
      <Grid item xs={12}>
        <Header>
          <Title>NPS - Satisfação do cliente</Title>
          <HelperPopover>
            <HelperPopoverTitle>
              O NPS mede a satisfação dos clientes nos últimos 45 dias.
            </HelperPopoverTitle>
          </HelperPopover>
        </Header>
        <div style={{ color }}>
          <Grade color="inherit">{zeroIfNothing(Math.round(nps?.score))}</Grade>
          <ExpectedResult>O resultado esperado é acima de 75%</ExpectedResult>
        </div>
      </Grid>
    </Root>
  );
}

export default BoxNpsIndex;
