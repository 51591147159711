/* eslint-disable promise/always-return */
/* eslint-disable promise/no-nesting */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
// TODO: Need refactor
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { Skeleton, Alert, AlertTitle } from '@mui/material';
import { useSession } from 'next-auth/react';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import Toast from '@/common/components/Toast/Toast';
import useCurrentAccount from '@/common/hooks/useCurrentAccount';
import { getSellerIndexHistoryLite } from '@/services/sellerIndexModule';
import {
  GridContainer,
  Root,
  Title,
  LineChartContainer,
  TextError,
} from './ChartSellerIndex.styled';

function ChartSellerIndex() {
  const { data: session } = useSession();
  const token = session?.accessToken;
  const [gData, setGData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const { currentAccount } = useCurrentAccount();

  useEffect(() => {
    const tempList = [];
    const getChartData = () => {
      if (!token || !currentAccount.id) return;
      try {
        getSellerIndexHistoryLite(currentAccount.codigo, token).then(
          (response) => {
            const { data: historyData, error: err } = response;
            if (err) {
              setError(true);
              return;
            }
            historyData.forEach((doc) => {
              const options = {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              };
              const date = new Date(doc.createDate);
              const index = Number.parseFloat(doc.score);
              if (index !== 0) {
                tempList.push({
                  createDate: date.toLocaleDateString('pt-BR', options),
                  grade: index,
                  date,
                });
              }
            });
            setGData(
              tempList.sort((a, b) => {
                return a.date.getTime() > b.date.getTime()
                  ? 1
                  : a.date.getTime() < b.date.getTime()
                  ? -1
                  : 0;
              }),
            );
          },
        );
      } catch {
        setError(true);
      } finally {
        setLoading(false);
      }
    };
    getChartData();
  }, [currentAccount, token]);

  const handleCloseToast = () => {
    setError(false);
  };

  if (loading)
    return <Skeleton variant="rectangular" width={387} height={255} />;
  if (gData.length === 0)
    return (
      <GridContainer>
        <Alert severity="info">
          <AlertTitle>Sem dados históricos</AlertTitle>
          Ainda não há histórico do Seller Index para este seller.
        </Alert>
      </GridContainer>
    );
  return (
    <Root>
      <Title>Histórico de notas</Title>
      <LineChartContainer width={387} height={255} data={gData}>
        <Line type="monotone" dataKey="grade" stroke="#dc143c" />
        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
        <XAxis dataKey="createDate" type="category" />
        <YAxis
          allowDecimals={false}
          dataKey="grade"
          type="number"
          tickCount={5}
          domain={[1, 5]}
          allowDataOverflow
        />
        <Tooltip />
        {error && (
          <Toast
            title="Ocorreu um erro ao carregar os dados"
            onClose={handleCloseToast}
            autoHideDuration={10_000}
          >
            <TextError>
              <FormattedMessage id="report.error.message" />
            </TextError>
          </Toast>
        )}
      </LineChartContainer>
    </Root>
  );
}

export default ChartSellerIndex;
