// TODO: Need refactor
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import {
  Container,
  Content,
  Circle,
  IconNota,
  Grade,
} from './NotaSeller.styled';

function NotaSeller({ grade }) {
  return (
    <Container>
      <Content grade={grade}>
        <CircularProgress
          color="inherit"
          variant="determinate"
          size={100}
          value={20 * grade}
        />
        <Circle>
          <IconNota />
        </Circle>
      </Content>
      <Grade grade={grade} component="div">
        {grade}
      </Grade>
    </Container>
  );
}

NotaSeller.propTypes = {
  grade: PropTypes.number.isRequired,
};

export default NotaSeller;
