/* eslint-disable react/jsx-filename-extension */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { useRouter } from 'next/router';
import { generateReportsPath } from '@/paths';
import Box from '../Box/index';

function BoxExpeditionIndex({ expedition }) {
  const router = useRouter();

  const title = 'Pedidos com atraso no despacho';
  const description = ' pedidos despachados com atraso de um total de ';

  const helpDescription = (
    <div style={{ padding: '10px' }}>
      Pedidos despachados com atraso nos últimos 45 dias que:
      <ul style={{ marginLeft: '15px' }}>
        <li>Foram despachados após a data limite; </li>
        <li>
          Não possuem informação de data de despacho, e a data limite já
          expirou.
        </li>
      </ul>
    </div>
  );

  function handleEvent() {
    router.push(generateReportsPath);
  }

  return (
    <Box
      grade={expedition}
      title={title}
      description={description}
      helpDescription={helpDescription}
      expectedResult={5}
      handleEvent={handleEvent}
    />
  );
}

// BoxExpeditionIndex.propTypes = {
//   expedition: PropTypes.shape({
//     percentage: PropTypes.number,
//     quantity: PropTypes.number,
//     total: PropTypes.number,
//   }),
// };
BoxExpeditionIndex.defaultProps = {
  expedition: { percentage: 0, quantity: 0, total: 0 },
};
export default BoxExpeditionIndex;
