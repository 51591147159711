import { TrendingUp as TrendingUpIcon } from '@grupo-sbf/motriz-icons/centauro';
import { Typography } from '@mui/material';
import styled, { css } from 'styled-components';

type NotaSellerProps = {
  grade: number;
};

const SUCCESS_GRADE = 3;

export const Container = styled.div`
  display: inline-flex;

  ${({ theme }) => css`
    margin: ${theme.spacing[300]} ${theme.spacing[0]};
  `};
`;

export const Content = styled.div<NotaSellerProps>`
  position: relative;
  display: inline-flex;

  ${({ theme, grade }) => css`
    background: ${grade > SUCCESS_GRADE
      ? theme.color.success[100]
      : theme.color.negative[100]} !important;
    border-radius: ${theme.borderRadius.circular};
    color: ${grade > SUCCESS_GRADE
      ? theme.color.brand.secondary
      : theme.color.brand.primary} !important;
  `};
`;

export const Circle = styled.div`
  position: absolute;
  width: 110px;
  height: 110px;
  left: calc(50% - 20px / 2);
  top: calc(50% - 110px / 2);
`;

export const IconNota = styled(TrendingUpIcon)`
  position: absolute;
  width: 65px;
  height: 58px;
  left: calc(50% - 65px / 2 - 46px);
  top: calc(50% - 58px / 2 - 2.93px);
`;

export const Grade = styled(Typography)<NotaSellerProps>`
  ${({ theme, grade }) => css`
    margin: ${theme.spacing[200]} ${theme.spacing[300]};
    font-size: ${theme.fontSize.huge};
    font-weight: ${theme.fontWeight.bold};
    line-height: ${theme.lineHeight.normal};
    color: ${grade > SUCCESS_GRADE
      ? theme.color.brand.secondary
      : theme.color.brand.primary} !important;
  `}
`;
