import { Button, Grid, Typography } from '@mui/material';
import styled, { css } from 'styled-components';

export const Root = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 420px;
  max-height: 238px;
  min-height: 194px;

  box-sizing: border-box;

  ${({ theme }) => css`
    padding: ${theme.spacing[200]};
    background: ${theme.color.neutral[100]};
    border: 1px solid ${theme.color.neutral[100]};
    border-radius: ${theme.borderRadius.large};
    margin: ${theme.spacing[0]} ${theme.spacing[200]} ${theme.spacing[200]}
      ${theme.spacing[0]};
  `}
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  ${({ theme }) => css`
    background: ${theme.color.neutral[100]};
  `}
`;

export const Title = styled(Typography)`
  height: 22px;
  width: 348px;

  ${({ theme }) => css`
    color: ${theme.color.neutral[700]};
    font-weight: ${theme.fontWeight.bold};
    font-size: ${theme.fontSize.small};
    line-height: ${theme.lineHeight.normal};
    margin: ${theme.spacing[0]} ${theme.spacing[0]};
  `};
`;

export const Grade = styled.h2`
  height: 47px;
  width: 388px;

  ${({ theme }) => css`
    font-weight: ${theme.fontWeight.bold};
    font-size: ${theme.fontSize.xlarge};
    line-height: ${theme.lineHeight.normal};
    margin: ${theme.spacing[200]} ${theme.spacing[0]};
  `};
`;

export const BoxButton = styled(Button)`
  min-height: 28px;
  width: 160px;
  text-transform: none;

  ${({ theme }) => css`
    font-weight: ${theme.fontWeight.bold};
  `};
`;
