import { Container } from './Faq.styled';

const Faq = () => {
  return (
    <Container>
      O Seller Index representa o seu nível de serviço no Marketplace da
      Centauro. A nota do Seller Index é composta por alguns indicadores
      operacionais e varia de <b>1</b> a <b>5</b>, sendo <b>5</b> a nota mais
      alta. Quer saber mais? Acesse o nosso FAQ no Menu lateral.
    </Container>
  );
};

export default Faq;
