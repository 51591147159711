import { Grid } from '@mui/material';
import { LineChart } from 'recharts';
import styled, { css } from 'styled-components';

export const GridContainer = styled(Grid)`
  width: 420px;

  ${({ theme }) => css`
    margin: ${theme.spacing[0]} ${theme.spacing[200]} ${theme.spacing[200]}
      ${theme.spacing[0]};
  `}
`;

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 420px;
  height: 314px;

  ${({ theme }) => css`
    background: ${theme.color.neutral[100]};
    border-radius: ${theme.borderRadius.large};
    margin: ${theme.spacing[0]} ${theme.spacing[200]} ${theme.spacing[500]}
      ${theme.spacing[0]};
  `};
`;

export const Title = styled.h4`
  width: 348px;
  height: 21px;

  ${({ theme }) => css`
    color: ${theme.color.neutral[700]};
    margin: ${theme.spacing[0]} ${theme.spacing[200]};
    font-size: ${theme.fontSize.xsmall};
    line-height: ${theme.lineHeight.normal};
    font-weight: ${theme.fontWeight.bold};
  `};
`;

export const LineChartContainer = styled(LineChart)`
  ${({ theme }) => css`
    margin: ${theme.spacing[50]} ${theme.spacing[50]} ${theme.spacing[50]}
      ${theme.spacing[0]};
    padding-top: ${theme.spacing[50]};
  `};
`;

export const TextError = styled.p`
  ${({ theme }) => css`
    margin: ${theme.spacing[50]} ${theme.spacing[0]} ${theme.spacing[200]}
      ${theme.spacing[0]};
    color: ${theme.color.neutral[700]};
    font-size: ${theme.fontSize.xtiny};
    line-height: ${theme.lineHeight.normal};
  `};
`;
