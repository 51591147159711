/* eslint-disable react/jsx-filename-extension */
import { Typography } from '@grupo-sbf/motriz-core';
import { Grid } from '@mui/material';
import { useTheme } from 'styled-components';
import zeroIfNothing from '@/common/utils';
import HelperPopover from '../../../../common/components/HelperPopover/HelperPopover';
import { Root, Header, Title, Grade, BoxButton } from './styled';

function Box({
  grade = { percentage: 0, quantity: 0, total: 0 },
  title,
  description,
  helpDescription,
  expectedResult,
  handleEvent = () => {},
  exportButton = true,
}) {
  const color = grade?.percentage <= expectedResult ? 'green' : 'red';
  const {
    color: { neutral },
  } = useTheme();

  return (
    <Root container>
      <Grid item xs={12}>
        <Header>
          <Title>{title}</Title>
          <HelperPopover>{helpDescription}</HelperPopover>
        </Header>
        <div style={{ color }}>
          <Grade color="inherit">{zeroIfNothing(grade?.percentage)}</Grade>
          <Typography type="simple" size="small" color={neutral[500]} mt="300">
            O resultado esperado é abaixo de {expectedResult}%
          </Typography>
        </div>
        <Typography
          type="simple"
          size="small"
          color={neutral[600]}
          mt="200"
          mb="100"
        >
          {zeroIfNothing(grade?.quantity)} {description}{' '}
          {zeroIfNothing(grade?.total)}
        </Typography>
        {exportButton && (
          <BoxButton
            variant="contained"
            color="primary"
            size="small"
            onClick={handleEvent}
          >
            Exportar pedidos
          </BoxButton>
        )}
      </Grid>
    </Root>
  );
}

export default Box;
