/* eslint-disable react/jsx-filename-extension */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import PropTypes from 'prop-types';
import Box from '../Box/index';

function BoxCancellationIndex({ cancellation }) {
  const title = 'Pedidos cancelados';
  const description = ' pedidos cancelados de um total de ';
  const helpDescription = (
    <div style={{ padding: '10px' }}>
      Pedidos pagos que foram cancelados pela sua loja ou pelo cliente nos
      últimos 45 dias.
    </div>
  );

  return (
    <Box
      grade={cancellation}
      title={title}
      description={description}
      helpDescription={helpDescription}
      expectedResult={1}
      exportButton={false}
    />
  );
}

BoxCancellationIndex.propTypes = {
  cancellation: PropTypes.shape({}).isRequired,
};
export default BoxCancellationIndex;
